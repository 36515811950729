"use client";

import { useReportWebVitals } from "next/web-vitals";
import { isProd } from "@/constants/core";

export function WebVitals() {
  useReportWebVitals((metric: { name: "TTFB" | "FCP" | "LCP" | "FID" | "CLS"; value: number; id: string }) => {
    if (isProd) {
      if (Object.hasOwn(window, "gtag")) {
        window.gtag("event", metric.name, {
          value: Math.round(metric.name === "CLS" ? metric.value * 1000 : metric.value), // values must be integers
          event_label: metric.id, // id unique to current page load
          non_interaction: true, // avoids affecting bounce rate.
        });
      }
    }
  });
  return null;
}
