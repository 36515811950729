import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/consent-scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/cookie-consent-panel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SolusImageComp"] */ "/app/components/footer/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ui/button-quino/copy-email-button-svg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ui/button-quino/link-with-mixpanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/components/web-vitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"service/fonts.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"]}],\"variableName\":\"OpenSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"service/fonts.ts\",\"import\":\"Sora\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-sora\"}],\"variableName\":\"SoraFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"service/fonts.ts\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-jakarta\"}],\"variableName\":\"PlusJakartaSans\"}");
