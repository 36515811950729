"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { LogoLanding } from "@/components/ui/icons/AppLogos";
import { LANDING_REDIRECT_ROUTES, PAGE_IDS, SOCIAL_MEDIA_LINKS } from "@/constants/landing";
import { Button } from "@/components/ui/button";
import { PlusJakartaSans } from "@/service/fonts";
import { cn } from "@/lib/utils";
import { VerticalLine, ArrowNorthEast, HamburgerIcon } from "@/components/icons/navbar";
import { DiscordIcon } from "@/components/ui/icons/social-icons";
import { CloseIcon } from "@/components/icons/navbar";
import LinkWithMixpanel from "@/components/ui/button-quino/link-with-mixpanel";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const TOP_OFFSET = 50;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={cn(
        "fixed z-[100] flex h-[64px] w-full max-w-[99.6vw] items-center font-medium",
        showBackground && "border-b-1 border-black/5 bg-white/80 backdrop-blur-[30px]",
        PlusJakartaSans.className,
      )}
    >
      <nav className="text-4 m-auto w-full max-w-[1180px] text-primary">
        <div className="container mx-auto flex items-center justify-between px-4 sm:px-6">
          <div>
            <Link href="/">
              <LogoLanding width={"140"} height={"38.9"} />
            </Link>
          </div>
          <div className="hidden xl:block">
            <ul className="flex items-center justify-center gap-[6px]">
              <li className="px-4 py-3">
                <Link href={`/#${PAGE_IDS.FEATURES}`}>Features</Link>
              </li>
              <li className="px-4 py-3">
                <Link href={`/#${PAGE_IDS.SUBSCRIPTION_PLANS}`}>Pricing</Link>
              </li>
              <li className="px-4 py-3">
                <Link href={`/#${PAGE_IDS.FAQ_ZONE}`}>FAQs</Link>
              </li>
              <li className="px-4 py-3">
                <Link href="/blog">Blog</Link>
              </li>
              <li className="px-4 py-3">
                <Link
                  href={SOCIAL_MEDIA_LINKS.DISCORD}
                  className="flex items-center justify-between gap-[8px]"
                  target="_blank"
                >
                  Join community
                  <DiscordIcon className="h-[16px] w-[16px]" />
                </Link>
              </li>
              <li className="px-4 py-3">
                <VerticalLine />
              </li>
              <li className="px-4 py-3">
                <LinkWithMixpanel href={LANDING_REDIRECT_ROUTES.REGISTER}>Get started</LinkWithMixpanel>
              </li>
              <li>
                <LinkWithMixpanel mixpanelEvent="open_app_button_clicked" href={LANDING_REDIRECT_ROUTES.LOGIN}>
                  <Button>
                    Open app
                    <ArrowNorthEast />
                  </Button>
                </LinkWithMixpanel>
              </li>
            </ul>
          </div>
          <div className="justify- flex items-center gap-[22px] xl:hidden">
            <ul className="hidden items-center justify-between gap-[6px] md:flex ">
              <li className="px-4 py-3">
                <LinkWithMixpanel href={LANDING_REDIRECT_ROUTES.REGISTER}>Get started</LinkWithMixpanel>
              </li>
              <li>
                <LinkWithMixpanel mixpanelEvent="open_app_button_clicked" href={LANDING_REDIRECT_ROUTES.LOGIN}>
                  <Button>
                    Open app
                    <ArrowNorthEast />
                  </Button>
                </LinkWithMixpanel>
              </li>
            </ul>
            <button onClick={() => setIsOpen(!isOpen)}>
              <HamburgerIcon />
            </button>
          </div>
          {isOpen && (
            <div className="absolute left-0 top-0 z-[100] h-full w-full bg-white xl:hidden">
              <div className="flex items-center justify-between p-4">
                <Link className="h-[38.9px] w-[140px]" href="/">
                  <LogoLanding width={"140"} height={"38.9"} />
                </Link>
                <button onClick={() => setIsOpen(!isOpen)}>
                  <CloseIcon />
                </button>
              </div>
              <ul className="-mt-2 flex flex-col items-center justify-center gap-[6px] bg-white pb-6">
                <li className="px-4 py-3">
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <Link href={`/#${PAGE_IDS.FEATURES}`}>Features</Link>
                  </button>
                </li>
                <li className="px-4 py-3">
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <Link href={`/#${PAGE_IDS.SUBSCRIPTION_PLANS}`}>Pricing</Link>
                  </button>
                </li>
                <li className="px-4 py-3">
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <Link href={`/#${PAGE_IDS.FAQ_ZONE}`}>FAQs</Link>
                  </button>
                </li>
                <li className="px-4 py-3">
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <Link href="/blog">Blog</Link>
                  </button>
                </li>
                <li className="px-4 py-3">
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <Link
                      href={SOCIAL_MEDIA_LINKS.DISCORD}
                      className="flex items-center justify-between gap-[8px]"
                      target="_blank"
                    >
                      Join community
                      <DiscordIcon className="h-[16px] w-[16px]" />
                    </Link>
                  </button>
                </li>
                <li className="mt-[38px] flex w-3/4 flex-col items-center min-[400px]:flex-row min-[400px]:justify-center md:hidden">
                  <div className="px-4 py-3">
                    <button onClick={() => setIsOpen(!isOpen)}>
                      <LinkWithMixpanel href={LANDING_REDIRECT_ROUTES.REGISTER}>Get started</LinkWithMixpanel>
                    </button>
                  </div>
                  <div className="max-[400px]:w-full">
                    <LinkWithMixpanel href={LANDING_REDIRECT_ROUTES.LOGIN} mixpanelEvent="open_app_button_clicked">
                      <Button
                        className="w-full"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        Open app
                        <ArrowNorthEast />
                      </Button>
                    </LinkWithMixpanel>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
