"use client";
import React, { forwardRef, useCallback } from "react";
import Link, { type LinkProps } from "next/link";
import { useForcedMixpanelRedirectTrack } from "@/service/hooks";

type EnhancedLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps & {
    children?: React.ReactNode;
    href: string;
    mixpanelEvent?: string;
    mixpanelEventPayload?: object;
  } & React.RefAttributes<HTMLAnchorElement>;

const LinkWithMixpanel = forwardRef<HTMLAnchorElement, EnhancedLinkProps>(
  (
    { as, prefetch, onClick, mixpanelEvent, mixpanelEventPayload, replace, shallow, scroll, href, children, ...rest },
    ref,
  ) => {
    const redirect = useForcedMixpanelRedirectTrack();

    const onClickInternal: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
      (e) => {
        if (!mixpanelEvent) {
          onClick && onClick(e);
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        redirect(href, mixpanelEvent, mixpanelEventPayload);
        onClick && onClick(e);
        return;
      },
      [href, mixpanelEvent, mixpanelEventPayload, onClick, redirect],
    );

    return (
      <Link
        href={href}
        as={as}
        ref={ref}
        prefetch={prefetch}
        replace={replace}
        shallow={shallow}
        scroll={scroll}
        onClick={onClickInternal}
        {...rest}
      >
        {children}
      </Link>
    );
  },
);

LinkWithMixpanel.displayName = "LinkWithMixpanel";

export default LinkWithMixpanel;
