"use client";

import { useCallback, useEffect, useMemo } from "react";
import { useConsentCookie } from "@/service/cookies/hooks";
import { ModalKeys } from "@/types";
import { Button } from "@/components/ui/button";
import { useLocalStorage } from "usehooks-ts";
import { CookieIcon } from "@/components/icons";
enum Action {
  ACCEPT,
  REJECT,
}

function CookieConsentPanel() {
  const [open, setModalKey] = useLocalStorage(ModalKeys.COOKIE_CONSENT_MODAL, false);
  const { acceptCookie, rejectCookie, isPresent, isAccepted } = useConsentCookie();

  const handleAction = useCallback(
    (action: Action): void => {
      if (action === Action.ACCEPT) {
        acceptCookie();
      } else rejectCookie();
    },
    [acceptCookie, rejectCookie],
  );

  useEffect(() => {
    if (isPresent) setModalKey(false);
    if (!isPresent) setModalKey(true);
  }, [isPresent, isAccepted, setModalKey]);

  return useMemo(() => {
    if (!open) return null;

    return (
      <section className="fixed bottom-0 left-0 z-[2000] flex w-full gap-4 overflow-hidden rounded-none border-t border-modal-stroke-default bg-container-default p-6 font-jakarta shadow-2xl md:bottom-6 md:left-6 md:max-w-[490px] md:rounded-2xl md:border md:p-4">
        <section>
          <span>
            <CookieIcon />
          </span>
        </section>
        <section className="flex flex-col justify-between">
          <h2 className="mb-1 font-bold leading-[135%] tracking-tight text-primitive-purple-900">Cookie Policy</h2>
          <p className="text-sm text-primitive-grey-800">
            We use cookies and similar technologies to help personalize content and provide a better experience. By
            clicking accept, you agree to this, as outlined in our <b>Cookie Policy</b>.
          </p>
          <section className="mt-6 flex flex-row gap-2">
            <Button variant="secondary" onClick={() => handleAction(Action.ACCEPT)}>
              Accept
            </Button>
            <Button variant="ghost" onClick={() => handleAction(Action.REJECT)}>
              Reject
            </Button>
          </section>
        </section>
      </section>
    );
  }, [handleAction, open]);
}

export default CookieConsentPanel;
