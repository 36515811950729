import { twMerge } from "tailwind-merge";
import React from "react";

export const ContainedButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button
      {...props}
      className={twMerge(
        "relative box-border inline-flex min-w-16 cursor-pointer appearance-none items-center justify-center rounded-lg bg-primary px-8 py-4 text-left align-middle font-bold text-white antialiased shadow-subscription-plan hover:bg-primary/75",
        props.className,
      )}
    />
  );
};

export const ContainedSecondaryButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button
      {...props}
      className={twMerge(
        "relative box-border inline-flex min-w-16 appearance-none items-center justify-center rounded-lg bg-primary/15 px-8 py-4 text-left align-middle font-bold text-primary antialiased transition duration-300 ease-in-out hover:bg-primary/25",
        props.className,
      )}
    />
  );
};

export const IconButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button
      {...props}
      className={twMerge(
        "inline-flex h-auto w-12 items-center justify-center rounded-full p-2 transition duration-300 ease-in-out hover:bg-primary/25",
        props.className,
      )}
    />
  );
};

export const OutlineButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button
      {...props}
      className={twMerge(
        "inline-flex h-auto min-w-16 items-center justify-center rounded-lg border border-primary bg-transparent px-8 py-4 font-semibold text-primary transition duration-300 ease-in-out hover:border-primary-saturated hover:bg-primary/5",
        props.className,
      )}
    />
  );
};

export const TextButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button
      {...props}
      className={twMerge(
        "relative inline-flex min-w-0 items-center justify-center rounded-lg bg-transparent text-left align-middle font-bold text-primary antialiased",
        props.className,
      )}
    />
  );
};
