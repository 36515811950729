"use client";
import { TextButton } from "@/components/ui/button-quino";
import Image from "next/image";
import SolusImage from "@/assets/banner/solus.png";
import { useEffect, useState } from "react";
import { fetchCountryIs } from "@/service/client";
import Link from "next/link";

const FooterLogin = () => <TextButton className="min-w-[8rem] justify-center lg:justify-start">Log in</TextButton>;
const ALLOWED_COUNTRY = "HU";
const SolusImageComp = () => {
  const [state, setState] = useState({ show: false, loading: true });
  useEffect(() => {
    void (async () => {
      const ipQuery = await fetchCountryIs();
      const res = await fetchCountryIs(ipQuery.ip);
      if (res.country === ALLOWED_COUNTRY) setState((prev) => ({ ...prev, show: true }));
    })().finally(() => setState((prevState) => ({ ...prevState, loading: false })));
  }, []);

  if (!state.show || state.loading) return null;
  return (
    <Link href="/szechenyi-2020">
      <div className="flex items-center py-4">
        <Image
          src={SolusImage.src}
          alt={"solus"}
          width={SolusImage.width}
          height={SolusImage.height}
          className="max-h-[14rem] w-auto"
        />
      </div>
    </Link>
  );
};

export { FooterLogin, SolusImageComp };
