import { useMemo, useCallback, useEffect } from "react";

import { useLocalStorage } from "usehooks-ts";
import { oneYearFromNowDate } from "../utils";
import { useCookies } from "react-cookie";
import { consentCookieName } from "@/service/cookies/constants";
import { QUINO_ROOT_DOMAIN } from "@/constants/landing";

const QUINO_DOMAIN: string = process.env.NODE_ENV === "development" ? "localhost" : QUINO_ROOT_DOMAIN;

const useConsentCookieData = () => useCookies([consentCookieName]);
export const useCookieCache = () => useLocalStorage<"ACCEPTED" | "REJECTED" | "UNSET">("COOKIE_CONSENT_CACHE", "UNSET");
const useIsConsentCookiePresent = (): boolean => {
  const [cookies] = useConsentCookieData();
  return useMemo(() => cookies.consent_cookie !== undefined, [cookies.consent_cookie]);
};

function useAcceptCookie() {
  const [, setCookie] = useConsentCookieData();
  const [, setCache] = useCookieCache();

  return useCallback(() => {
    try {
      console.log(QUINO_DOMAIN)
      setCache("ACCEPTED");
      setCookie(consentCookieName, true, {
        expires: oneYearFromNowDate(),
        secure: true,
        maxAge: 60 * 60 * 24 * 365,
        domain: `.${QUINO_DOMAIN}`,
      });
    } catch (e) {
      console.error(e);
    }
  }, [setCache, setCookie]);
}

function useRejectCookie() {
  const [, setCookie] = useConsentCookieData();
  const [, setCache] = useCookieCache();

  return useCallback(() => {
    setCache("REJECTED");
    setCookie(consentCookieName, false, {
      expires: oneYearFromNowDate(),
      secure: true,
      maxAge: 60 * 60 * 24 * 365,
    });
  }, [setCache, setCookie]);
}

function useIsCookieConsentAccepted(): boolean {
  const [cookies] = useConsentCookieData();
  return useMemo(() => cookies.consent_cookie === true, [cookies.consent_cookie]);
}

export const useHandleInitialization = () => {
  const [data, setCookie] = useConsentCookieData();
  const [cache] = useCookieCache();
  const isPresent = useIsConsentCookiePresent();

  useEffect(() => {
    if (data.consent_cookie === undefined && cache !== "UNSET") {
      if (cache === "ACCEPTED") {
        setCookie(consentCookieName, true, {
          expires: oneYearFromNowDate(),
        });
      } else {
        setCookie(consentCookieName, false, {
          expires: oneYearFromNowDate(),
        });
      }
    }
  }, [cache, data.consent_cookie, isPresent, setCookie]);
};
const useConsentCookie = () => {
  const isPresent = useIsConsentCookiePresent();
  const isAccepted = useIsCookieConsentAccepted();
  const acceptCookie = useAcceptCookie();
  const rejectCookie = useRejectCookie();

  return { isPresent, isAccepted, acceptCookie, rejectCookie };
};

export { useIsConsentCookiePresent, useAcceptCookie, useRejectCookie, useIsCookieConsentAccepted, useConsentCookie };
